.brutal-container {  
    position: absolute;
    bottom: 0;
    width: 440px;
    height: 298px;
    font-family: "Rubik", sans-serif;
    display: -webkit-flex;
    display: flex;
  }

  @media screen and (max-device-width: 480px) {
    .brutal-container {
        width: 320px;
      }
} 

  .theme-containter-light {
    background-color: #eff1f9;
    color: #7b7e8a;
    border: 1px solid #e3e5ef;
    border-radius: 4px;
  }

  .theme-containter-purple {
    background-color: #6D5888;
    color: #fff;
    border: 0px solid #6D5888;
    border-radius: 8px;
  }
  
 
  .pfm-hide {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 75%;
    cursor: pointer;
  }
  
  .pfm-option{
    text-align: center;
    padding: 12px;
    margin: 0px 0px 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    
  }
  
  .theme-pfm-option-light {
    color: #79868C;
    border: 1px solid #e3e5ef;
    background-color: white;
  }

  .theme-pfm-option-light:hover {
    background-color: #f5f7ff;
    border: 1px solid #d7d9e4;
  }

  .theme-pfm-option-purple {
    color: #00B796;
    background-color: white;
    border: 0px solid #d7d9e4;
    font-weight: bold;
  }

  .theme-pfm-option-purple:hover {
    background-color: #E6FFFB;
    border: 0px solid #d7d9e4;
  }
  
  .pfm-question {
    padding: 20px 30px;
    text-align: center;
    line-height: 150%;
    font-size: 120%;
    font-weight: 300;
   }
  
  .brutal-pfm-block {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-align-self: center;
            align-self: center;
    width: 100%;
  }
  
  .slide-in-bottom {
      -webkit-animation: slide-in-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
              animation: slide-in-bottom 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
  }
  
  /**
   * ----------------------------------------
   * animation slide-in-bottom
   * ----------------------------------------
   */
   @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
  
  .fade-in {
      -webkit-animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
              animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  
  /**
   * ----------------------------------------
   * animation fade-in
   * ----------------------------------------
   */
   @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .blink-1 {
      -webkit-animation: blink-1 0.6s both;
              animation: blink-1 0.6s both;
  }
  
  
  /**
   * ----------------------------------------
   * animation blink-1
   * ----------------------------------------
   */
   @-webkit-keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
  @keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }


  .btn {
    font-size: 70%;
    padding: 10px 26px 10px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}


input {
    width: -webkit-fill-available;
    height: 40px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #b4b9c2;
    padding: 0 10px;
    font-size: 100%;
}

.btn-theme-light {
    color: #fff;
    background-color: #8A69D4;
    border-color: #8A69D4;
}

.btn-theme-purple {
    color: #fff;
    background-color: #00B796;
    border-color: #00B796;
}
